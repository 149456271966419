import React, { useEffect, useState, useRef } from 'react';
import store, { actions, getters } from 'redux/store';
import { useSelector } from 'react-redux';
import { decode } from 'js-base64';
import { scroller } from 'react-scroll';

import DeliveryBar from 'components/delivery-bar';
import NewAddress from 'components/map8-new-address';
import AddressSearch from 'components/address-search/index.jsx';

import LocationRadio from './LocationRadio';

import Cancel from 'images/icons/i-cancel.svg';

import { useAPI } from 'utils/api';
import WebFn, { callAppFunc } from 'utils/jsbridge/index.js';
import { dismissLoading, showLoading } from 'utils/loading';
import { setShippingAddress } from 'utils/_ga';

const IN_APP = process.env.GATSBY_INAPP === 'true';

const handleStoreRadioScrollTo = (id) => {
  scroller.scrollTo(`store-item-${id}`, {
    duration: 1000,
    delay: 100,
    smooth: true,
    containerId: 'store-radio-group',
    offset: 0,
    horizontal: true,
  });
};

const AddressBlock = ({ setAnyAction, setShowAddressSearch }) => {
  return (
    <>
      <div className="address-bar">
        <div className="address-block">
          <div className="address-input-frame">
            {/* <img src={imageLocationPoint} /> */}
            <div className="address">
              <input
                type="text"
                className="address-input"
                placeholder="請輸入新地址"
                onClick={() => {
                  setAnyAction(true);
                  setShowAddressSearch(true);
                }}
                readOnly
              />
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .address-bar {
          width: 100%;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 5px solid #f7f7f7;
        }
        .address-block {
          width: 335px;
          height: 47px;
          background: #ffffff;
          border: 1px solid #5fd2da;
          box-sizing: border-box;
          padding: 8px 12.5px 8px 5.15px;
          border-radius: 8px;
          display: grid;
          .address-input-frame {
            padding-bottom: 4px;
            display: flex;
            align-items: center;
            .address {
              flex-grow: 1;
              // margin: 0 1.6vw;
              padding: 0 5px;
              color: #333333;
              .address-input {
                padding: 0;
                width: 100%;
                background: transparent;
                border: 0;
                outline: none;
              }
            }
            .remove_address {
              height: 14px;
              margin-right: 4px;
            }
          }
          .store {
            font-weight: bold;
            padding: 0 5px;
            // margin: 0 2vw;
            color: #333333;
          }
        }
      `}</style>
    </>
  );
};

const DispatchSetting = () => {
  const api = useAPI();

  // near by stores
  const nearByStores = useSelector((state) => getters.getNearByStores(state));
  // address data
  const address = useSelector((state) => getters.getAddress(state));
  // store timeslots
  const storeTimeSlots = useSelector((state) =>
    getters.getStoreTimeSlots(state)
  );

  const hashAccount = useSelector((state) => getters.getHashAccount(state));

  // select address
  const [isShowEdit, setIsShowEdit] = useState(false);
  const [selectAdderssData, setSelectAddressData] = useState({});
  const [selectEditData, setSelectEditData] = useState({});

  const [currentAddress, setCurrentAddress] = useState('');

  const [anyAction, setAnyAction] = useState(false);

  // store select
  const [selectStore, setSelectStore] = useState('');

  // new addresses
  const [newAddressKey, setNewAddressKey] = useState(false);
  const [newAddresses, setNewAddresses] = useState('');

  const [selectTimeslot, setSelectTimeslot] = useState({});
  const [selectTimeslotIndex, setSelectTimeslotIndex] = useState(0);

  //gps
  const [gpsOpend, setGpsOpend] = useState(false);
  const [intervalId, setIntervalId] = useState(0);

  const [showAddressSearch, setShowAddressSearch] = useState(false);
  const [AddrFloor, setAddrFloor] = useState('');
  const [memberProfile, setMemberProfile] = useState({});

  // post data
  // const [postData, setPostData] = useState({});
  const postData = useRef({});

  const onChangeStore = (event) => {
    setSelectStore(event.currentTarget.id);
    setAnyAction(true);
  };

  const [gpsLatlng, setGpslatlng] = useState({});
  const [gpsLocation, setGpsLocation] = useState('');

  const getNearStore = (lat, lng, addr) => {
    showLoading();
    store.dispatch(actions.setNearByStores([]));
    api
      .getStoreByLocation(lat, lng, addr)
      .then((res) => {
        if (!res) {
          dismissLoading();
          return;
        }
        store.dispatch(actions.setNearByStores(res.rows));
        dismissLoading();
      })
      .catch((error) => {
        console.log('get location by server exception =', error);
        dismissLoading();
      });
  };

  const phoneFormatValid = (phone) => {
    if (phone === undefined || phone === '') {
      return false;
    }

    var mobileRegex = /^09\d{8}$/;

    return mobileRegex.test(phone);
  };

  const onClickUpdateAddress = (addressData) => {
    if (!addressData.fullName) {
      alert('請填寫姓名');
      return;
    }
    if (!addressData.phone) {
      alert('請填寫手機號碼');
      return;
    }
    if (!phoneFormatValid(addressData.phone)) {
      alert('手機號碼格式錯誤');
      return;
    }
    showLoading();
    if (newAddressKey) {
      api
        .newAddress(addressData)
        .then((res) => {
          setIsShowEdit(false);
          getAddress();
          saveData(addressData);
          dismissLoading();
        })
        .catch((error) => {
          dismissLoading();
        });
    } else {
      api
        .updateAddress(addressData)
        .then((res) => {
          dismissLoading();
          if (!res) return;
          setIsShowEdit(false);
          getAddress();
          saveData(addressData);
        })
        .catch((error) => {
          dismissLoading();
          alert(error);
        });
    }
  };
  const getAddressDetail = (addressId) => {
    api
      .getAddressDetail(addressId)
      .then((res) => {
        setSelectEditData(res);
        setIsShowEdit(true);
      })
      .catch((error) => {});
  };

  const getAddress = () => {
    api
      .getAddress()
      .then((res) => {
        if (!res) {
          return;
        }
        store.dispatch(actions.setAddress(res.rows));

        // 判斷是否需要使用預設地址
        // 地址爲空，使用預設
        if (
          (IN_APP &&
            (postData.current.receiverAddress === undefined ||
              postData.current.receiverAddress === null ||
              postData.current.receiverAddress === '')) ||
          (!IN_APP &&
            (currentAddress === undefined ||
              currentAddress === null ||
              currentAddress === ''))
        ) {
          const result = res.rows.find((addr, index) => addr.isDefault);
          setSelectAddressData(result);
        }
      })
      .catch((err) => {});
  };

  const getTimeSlots = () => {
    api
      .getStoreTimeSlots(selectStore)
      .then((res) => {
        if (!res) {
          setSelectTimeslot({});
          return;
        }
        store.dispatch(actions.setStoreTimeSlots(res));
        if (res.length > 0) {
          setSelectTimeslot(res[0]);
        } else {
          setSelectTimeslot({});
        }
      })
      .catch((err) => {});
  };

  const jsBridgeCallback = (strJson) => {
    const dataJson = JSON.parse(strJson);
    if (dataJson.fnName === 'sendDispatchData') {
      const data = JSON.parse(decode(dataJson.params.data));
      handleAppData(data);
      return;
    }
    if (dataJson.fnName === 'gpsOpened') {
      if (!dataJson.params.data) {
        const id = setInterval(() => {
          const gpsCallback = (strJson) => {
            const dataJson = JSON.parse(strJson);
            if (dataJson.fnName === 'gpsOpened') {
              setGpsOpend(dataJson.params.data);
            }
          };
          callAppFunc('getIsGpsOpened', {});
          WebFn({ callback: gpsCallback });
        }, 1000);
        setIntervalId(id);
      }
      setGpsOpend(dataJson.params.data);
      return;
    }
  };

  const handleAppData = (data = postData.current) => {
    postData.current = data;
    if (
      postData.current.receiverAddress != null &&
      postData.current.receiverAddress !== undefined &&
      postData.current.receiverAddress !== ''
    ) {
      setCurrentAddress(postData.current.receiverAddress);
      getNearStore(null, null, postData.current.receiverAddress);
      setSelectStore(postData.current.storeId);
    }

    // get save addresses
    getAddress();
  };

  const saveData = (addressData) => {
    if (!currentAddress) {
      alert('目前找不到此地址，請重新填寫地址');
      return;
    }
    if (!selectStore) {
      confirm('尚未選擇配送/取貨門店', '選擇門店').then(() => {
        getAddressDetail(addressData.addressId);
      });
      return;
    }

    const findObj = nearByStores.find((store) => {
      return store.id === addressData.defaultStoreId;
    });

    if (findObj === undefined || findObj === null) {
      confirm('此門店暫不配送此地址，請更換門店').then(() => {
        getAddressDetail(addressData.addressId);
      });
      return;
    }
    if (!anyAction) {
      setShippingAddress(hashAccount);
    }

    const isFirstSelectStore =
      postData.current.storeId === undefined ||
      postData.current.storeId === null ||
      postData.current.storeId === '';

    var isChangeStore =
      selectStore !== postData.current.storeId &&
      postData.current.storeId !== undefined &&
      postData.current.storeId !== null &&
      postData.current.storeId !== '';

    // 原本判斷是否更改的條件是select store 與 postData store不同
    // 但app增加store檢查後，可能直接更改store id
    // 造成不能單純使用select store 與 postData store做檢查
    // 增加一個findObj與postData store的檢查
    // 並且如果是findObj.storeId !== postData storeId的話
    // 表示app端更改過store資訊
    // selectStore需更新為findObj的storeId
    // 檢查才會合理

    var payloadStoreId = selectStore;

    if (!isChangeStore && findObj.id !== postData.current.storeId) {
      isChangeStore = true;
      setSelectStore(findObj.id);
      payloadStoreId = postData.current.storeId = findObj.id;
      postData.current.storeName = findObj.name;
    }

    if (isChangeStore || isFirstSelectStore) {
      if (isFirstSelectStore) {
        dispatchDataHandle(addressData);
        showLoading();
        api
          .changeStore({ storeId: selectStore })
          .then((res) => {
            dismissLoading();
            callAppFunc('saveDispatchData', {
              data: JSON.stringify(postData.current),
            });

            callAppFunc('dismissDispatchSetting', {});
          })
          .catch((error) => {
            dismissLoading();
          });
      } else {
        confirm(
          '切換出貨地址或選擇其他門店，原購物車內商品將會清除',
          '更換門店'
        ).then(() => {
          dispatchDataHandle(addressData);
          showLoading();
          api
            .changeStore({ storeId: payloadStoreId })
            .then((res) => {
              dismissLoading();
              callAppFunc('saveDispatchData', {
                data: JSON.stringify(postData.current),
              });

              callAppFunc('dismissDispatchSetting', {});
            })
            .catch((error) => {
              dismissLoading();
            });
        });
      }
    } else {
      dispatchDataHandle(addressData);
      callAppFunc('saveDispatchData', {
        data: JSON.stringify(postData.current),
      });

      callAppFunc('dismissDispatchSetting', {});
    }
  };

  const dispatchDataHandle = (addressData) => {
    const findObj = nearByStores.find((store) => {
      return store.id === addressData.defaultStoreId;
    });
    postData.current = {
      ...postData.current,
      storeId: addressData.defaultStoreId,
      storeName: findObj.name,
      storeAddress: findObj.address,
      shippingDate: selectTimeslot ? selectTimeslot.shippingDate ?? '' : '',
      shippingPeriod: selectTimeslot ? selectTimeslot.shippingPeriod ?? '' : '',
      shippingTimeslot: selectTimeslot
        ? selectTimeslot.shippingTimeslot ?? ''
        : '',
      fastDeliveryPeriod: selectTimeslot
        ? selectTimeslot.fastDeliveryPeriod ?? ''
        : '',
    };

    postData.current =
      typeof addressData === 'object' && Object.keys(addressData).length > 0
        ? {
            ...postData.current,
            receiverName: addressData.fullName ?? '',
            receiverPhone: addressData.phone ?? '',
            receiverAddress: addressData.address ?? '',
            receiverElevator: addressData.deliveryIsElevator ? '1' : '0',
            receiverReceive: addressData.deliveryPointValue ?? '',
            receiverAddressRemark: addressData.deliveryNote ?? '',
            addressId: addressData.addressId ?? '',
          }
        : {
            ...postData.current,
            receiverAddress: addressData.address,
            receiverPhone: '',
            receiverName: '',
            addressId: '',
            receiverElevator: '',
            receiverReceive: '',
            receiverAddressRemark: '',
          };

    const tmpJsonString = localStorage.getItem('TmpCartData');
    if (tmpJsonString !== null) {
      var tmpJson = JSON.parse(tmpJsonString);
      tmpJson = {
        ...tmpJson,
        receiverAddress: currentAddress,
        storeName: findObj.name,
      };

      if (
        typeof addressData === 'object' &&
        Object.keys(addressData).length > 0
      ) {
        tmpJson = {
          ...tmpJson,
          receiverName: addressData.fullName ?? '',
          receiverPhone: addressData.phone ?? '',
          receiverAddress: addressData.address ?? '',
          receiverElevator: addressData.deliveryIsElevator ? '1' : '0',
          receiverReceive: addressData.deliveryPointValue ?? '',
          receiverAddressRemark: addressData.deliveryNote ?? '',
          addressId: addressData.addressId ?? '',
        };
      }

      localStorage.setItem('TmpCartData', JSON.stringify(tmpJson));
    } else {
      localStorage.setItem('TmpCartData', JSON.stringify(postData.current));
    }
  };
  const seachSetAddress = (address) => {
    setSelectEditData({});
    setSelectStore('');
    setNewAddresses(address);
    setNewAddressKey(true);
    setIsShowEdit(true);
  };
  const GpsLocation = (gpsLatlng) => {
    const latlng = `${gpsLatlng.lat},${gpsLatlng.lng}`;
    api
      .getGpsLocation(latlng)
      .then((res) => {
        setGpsLocation(res[0].formatted_address);
      })
      .catch((err) => {});
  };
  const getMemberProfile = () => {
    api
      .getMemberProfile()
      .then((res) => {
        setMemberProfile({
          name: res.name,
          phone: res.mobile,
        });
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (
      selectAdderssData !== undefined &&
      typeof selectAdderssData === 'object' &&
      Object.keys(selectAdderssData).length > 0
    ) {
      const fullAddress = selectAdderssData.address;
      setCurrentAddress(fullAddress);
    }
  }, [selectAdderssData]);

  useEffect(() => {
    setSelectTimeslotIndex();
    setTimeout(() => {
      setSelectTimeslotIndex(0);
    }, 500);
    getTimeSlots();
  }, [selectStore]);

  useEffect(() => {
    if (!IN_APP) {
      // get save addresses
      getAddress();
      return;
    }
    getMemberProfile();

    WebFn({ callback: jsBridgeCallback });
    callAppFunc('fetchDispatchData', {});
    callAppFunc('getIsGpsOpened', {});
  }, []);

  useEffect(() => {
    if (
      typeof postData.current === 'object' &&
      Object.keys(postData.current).length > 0
    ) {
      // 表示有預設資料
      const result = address.find(
        (addr, index) => addr.addressId === postData.current.addressId
      );
      const addressResult = address.find(
        (addr) => addr.address === postData.current.receiverAddress
      );
      if (result) {
        setSelectAddressData(result);
      } else {
        setSelectAddressData(addressResult);
      }
    }
  }, [address]);

  useEffect(() => {
    if (gpsOpend) {
      clearInterval(intervalId);
      const gpsOpenedCallBack = (strJson) => {
        const dataJson = JSON.parse(strJson);
        if (dataJson.fnName === 'sendGPS') {
          setGpslatlng({
            lat: Number(dataJson.params.latitude),
            lng: Number(dataJson.params.longitude),
          });
        }
      };
      callAppFunc('fetchGPS', {});
      WebFn({ callback: gpsOpenedCallBack });
    }
  }, [gpsOpend]);

  useEffect(() => {
    if (
      typeof postData.current === 'object' &&
      Object.keys(postData.current).length > 0
    ) {
      // 表示有預設資料
      var findIndex = 0;
      var findData = false;

      const result = storeTimeSlots.find((slot, index) => {
        if (
          selectStore === postData.current.storeId &&
          slot.shippingDate === postData.current.shippingDate &&
          slot.fastDeliveryPeriod === postData.current.fastDeliveryPeriod &&
          slot.shippingPeriod === postData.current.shippingPeriod &&
          slot.shippingTimeslot === postData.current.shippingTimeslot
        ) {
          findIndex = index;
          return true;
        }
      });
      if (result !== undefined && result != null) {
        setSelectTimeslot(result);
        setSelectTimeslotIndex(findIndex);
        findData = true;
      } else {
        if (
          postData.current.shippingPeriod === '-1' &&
          postData.current.fastDeliveryPeriod === ''
        ) {
          const resultEnd = storeTimeSlots.find((slot, index) => {
            if (slot.shippingPeriod === '-1') {
              findIndex = index;
              return true;
            }
          });

          if (resultEnd !== undefined && resultEnd !== null) {
            setSelectTimeslot(resultEnd);
            setSelectTimeslotIndex(findIndex);
            findData = true;
          }
        }
      }
      if (!findData && storeTimeSlots.length > 0) {
        setSelectTimeslot(storeTimeSlots[0]);
        setSelectTimeslotIndex(0);
        findData = true;
      }
    }
  }, [storeTimeSlots]);

  useEffect(() => {
    if (gpsLatlng !== undefined && gpsOpend) {
      GpsLocation(gpsLatlng);
    }
  }, [gpsLatlng]);

  useEffect(() => {
    if (!isShowEdit) {
      getAddress();
    }
  }, [isShowEdit]);

  return (
    <>
      <div className="page">
        <div className="header-bar">
          <img
            src={Cancel}
            alt="cancel"
            className="header-cancel"
            onClick={() => {
              if (
                postData.current.storeId === undefined ||
                postData.current.storeId === ''
              ) {
                alert('目前找不到此地址，請重新填寫地址');
                return;
              }
              callAppFunc('dismissDispatchSetting', { needRefresh: 'false' });
            }}
          />
        </div>
        <DeliveryBar
          defaultSelectIndex={selectTimeslotIndex}
          timeslots={storeTimeSlots}
          setSelectTimeSlots={setSelectTimeslot}
          setAnyAction={setAnyAction}
          shippingModel={IN_APP ? postData.current.shippingModel : '1'}
          getTimeSlots={getTimeSlots}
        />
        <div className="map-section">
          <AddressBlock
            setAnyAction={setAnyAction}
            setShowAddressSearch={setShowAddressSearch}
          />
        </div>
        <div className="curr-locations">
          {gpsOpend ? (
            <div>
              <div>
                <h2 style={{ color: '#5FD2DA' }}>使用目前定位地點</h2>
              </div>
              <div
                className="address"
                style={{ fontWeight: 'bold', textDecoration: 'underline' }}
                onClick={() => {
                  seachSetAddress(gpsLocation);
                }}
              >
                {gpsLocation}
              </div>
            </div>
          ) : (
            <div>
              <div style={{ marginTop: '20px', display: 'flex' }}>
                <h2 style={{ color: '#999999' }}>使用目前定位地點</h2>
                <button
                  className="gps-btn"
                  onClick={() => {
                    callAppFunc('openGps', {});
                  }}
                >
                  啟用
                </button>
              </div>
              <h2 style={{ fontWeight: 'normal', fontSize: '16px' }}>
                已關閉定位存取權
              </h2>
            </div>
          )}
        </div>
        <div className="saved-locations">
          <h2>常用地點</h2>
          <div className="location-list">
            {address.map((item, index) => (
              <LocationRadio
                key={index}
                data={item}
                setIsShow={setIsShowEdit}
                setSelectData={setSelectAddressData}
                setSelectEditData={setSelectEditData}
                setAnyAction={setAnyAction}
                currentSelectData={selectAdderssData}
                currentInputAddress={currentAddress}
                setNewAddresses={setNewAddresses}
                setNewAddressKey={setNewAddressKey}
                setSelectStore={setSelectStore}
                getStore={getNearStore}
                getAddressDetail={getAddressDetail}
              />
            ))}
          </div>
        </div>
        <div className="button-block">
          <div onClick={() => saveData(selectAdderssData)} className="button">
            確認
          </div>
        </div>
      </div>
      <NewAddress
        key={isShowEdit}
        isShow={isShowEdit}
        setIsShow={setIsShowEdit}
        data={selectEditData}
        clickUpdate={onClickUpdateAddress}
        nearByStores={nearByStores}
        onChangeStore={onChangeStore}
        selectStore={selectStore}
        handleStoreRadioScrollTo={handleStoreRadioScrollTo}
        postData={postData}
        setSelectStore={setSelectStore}
        gpsLatlng={gpsLatlng}
        newAddressKey={newAddressKey}
        newAddress={newAddresses}
        memberProfile={memberProfile}
        AddrFloor={AddrFloor}
        setSelectAddressData={setSelectAddressData}
        setCurrentAddress={setCurrentAddress}
      />
      <AddressSearch
        isShow={showAddressSearch}
        setShowblock={() => setShowAddressSearch(false)}
        setAddress={seachSetAddress}
        setAddrFloor={setAddrFloor}
      />

      <style jsx>{`
        .page {
          position: relative;
        }
        .header-bar {
          height: 24px;
          width: 100%;
          padding: 14px 0 12px;
          position: relative;
        }
        .header-cancel {
          position: absolute;
          top: 14px;
          left: 23px;
          display: inline-block;
          // height: 24px;
          height: 17.56px;
          margin-right: 8px;
        }
        .map-section {
          position: relative;
        }
        #map {
          width: 100%;
          height: 204px;
          background-color: #aaa;
          position: relative;
        }
        .store-list {
          overflow-x: scroll;
          margin-top: -33px;
          padding-bottom: 30px;
          &::-webkit-scrollbar {
            display: none;
          }
        }
        .curr-locations {
          padding: 0 15.5px 24px 14px;
        }
        h2 {
          padding-bottom: 11px;
        }
        .saved-locations,
        .recent-locations {
          min-height: 44px;
          padding: 0 15.5px 100px 14.5px;
        }
        .recent-location {
          display: flex;
          justify-content: space-between;
          padding-top: 16.5px;
          padding-bottom: 12.5px;
          border-bottom: 1px solid #e4e4e4;
        }

        .button-block {
          position: fixed;
          bottom: 0;
          width: calc(100vw - 32px);
          height: 60px;
          padding: 20px 16px;
          background-color: #ffffff;
          box-shadow: 0px 10px 15px 10px #e0e0e0;
          display: flex;
          justify-content: center;
          align-items: center;
          .button {
            width: 100%;
            height: 60px;
            padding: 0 35px;
            background: #5fd2da;
            border: 1px solid #5fd2da;
            box-sizing: border-box;
            border-radius: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            line-height: 29px;
            color: #ffffff;
          }
        }
        .gps-btn {
          width: 62px;
          height: 30px;
          margin-left: auto;
          border: 1px black solid;
          line-height: 0px;
          text-align: center;
          color: black;
          font-size: 14px;
          font-weight: normal;
        }
      `}</style>
    </>
  );
};

export default DispatchSetting;
